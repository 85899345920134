<template>

  <PButton
    @click="onClick"
    :loading="isClearing"
  >
    <span v-html="$tk('GlobalCaching.EmptyCache')"></span>
  </PButton>

</template>

<script>

import http from '@/utilities/http'

export default {

name: "GlobalCaching",

  data() {
    return {
      isClearing: false
    }
  },

  methods: {

    async onClick() {

      this.isClearing = true

      try {

        await http.post("reset")

      } catch (error) {

        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })

      }

      this.isClearing = false
    }
  }

}
</script>
